import Dayjs from 'dayjs'

export function formatDate(date: string) {
  return new Date(date).toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    timeZone: 'UTC',
  })
}

export function isValidDate(date: unknown) {
  return date instanceof Date && !isNaN(date.getTime())
}

export function formatDateForNews(date: string) {
  const articleDate = Dayjs(date)
  const now = Dayjs()

  console.log(articleDate, now)
  const diffInDays = now.diff(articleDate, 'day')
  if (diffInDays >= 2) {
    return articleDate.format('M/D/YYYY')
  } else if (diffInDays === 1) {
    return 'Yesterday'
  }

  const diffInHours = now.diff(articleDate, 'hour')
  if (diffInHours >= 1) {
    return `${diffInHours} hour${diffInHours === 1 ? '' : 's'} ago`
  }

  const diffInMinutes = now.diff(articleDate, 'minute')
  return `${diffInMinutes} minute${diffInMinutes === 1 ? '' : 's'} ago`
}
