import type { PortableTextBlock } from '@portabletext/types'
import type { ImageAsset, Slug } from '@sanity/types'
import { Dayjs } from 'dayjs'
import groq from 'groq'
import { type SanityClient } from 'next-sanity'

export const postsByTagsQuery = (
  tags: string[],
  startIndex: number,
  stopIndex: number,
) => {
  const tagsList = '[' + tags.map((tag) => `"${tag}"`).join(',') + ']'
  return groq`
*
[
  _type == "post"
  && defined(slug.current)
  && 
  count((tags[]->name)[@ in ${tagsList}]) > 0
] | order(time desc)
[${startIndex}...${stopIndex}]
{
  title,
  slug,
  date,
  description,
  mainImage,
  "tagNames": tags[]->name,
  body,
  author,
  siteName,
  link, 
  time
}`
}

export async function getPostsByTags(
  client: SanityClient,
  tags: string[],
  startIndex: number,
  stopIndex: number,
): Promise<Post[]> {
  if (tags.length === 0) {
    return await client.fetch(postsQuery(startIndex, stopIndex), undefined, {
      next: {
        revalidate: 3600,
      },
    })
  } else {
    const preparedQuery = postsByTagsQuery(tags, startIndex, stopIndex)
    return await client.fetch(preparedQuery, tags)
  }
}

export const communityPostsQuery = (
  startIndex: number,
  stopIndex: number,
) => groq`*[_type == "communityPost"] | order(date desc)
[${startIndex}...${stopIndex}]
{
  title,
  slug,
  date,
  description,
  mainImage,
  "tagNames": tags[]->name,
  body
}`

export const communityPostsByTagsQuery = (
  tags: string[],
  startIndex: number,
  stopIndex: number,
) => {
  const tagsList = '[' + tags.map((tag) => `"${tag}"`).join(',') + ']'
  return groq`
*
[
  _type == "communityPost"
  && defined(slug.current)
  && 
  count((tags[]->name)[@ in ${tagsList}]) > 0
] | order(date desc)
[${startIndex}...${stopIndex}]
{
  title,
  slug,
  date,
  description,
  mainImage,
  "tagNames": tags[]->name,
  body
}`
}

export async function getCommunityPostsByTags(
  client: SanityClient,
  tags: string[],
  startIndex: number,
  stopIndex: number,
): Promise<Post[]> {
  if (tags.length === 0) {
    return await client.fetch(communityPostsQuery(startIndex, stopIndex))
  } else {
    const preparedQuery = communityPostsByTagsQuery(tags, startIndex, stopIndex)
    return await client.fetch(preparedQuery, tags)
  }
}

export const postsQuery = (
  startIndex: number,
  stopIndex: number,
) => groq`*[_type == "post" && defined(slug.current)] | order(date desc)
[${startIndex}...${stopIndex}]
{
  title,
  slug,
  date,
  description,
  mainImage,
  "tagNames": tags[]->name,
  body,
  author,
  siteName,
  link
}`

const postsCountQuery = groq`count(
  *[_type == "post"
&& defined(slug.current)])`

const postsCountByTagsQuery = (tags: string[]) => {
  const tagsList = '[' + tags.map((tag) => `"${tag}"`).join(',') + ']'
  return groq`count(
       *[_type == "post"
    && defined(slug.current)
    && count((tags[]->name)[@ in ${tagsList}]) > 0])`
}
export const getPostsCount = (
  client: SanityClient,
  tags: string[],
): Promise<number> => {
  if (tags.length > 0) {
    return client.fetch(postsCountByTagsQuery(tags), undefined, {
      next: {
        revalidate: 3600,
      },
    })
  } else {
    return client.fetch(postsCountQuery, undefined, {
      next: {
        revalidate: 3600,
      },
    })
  }
}

const communityPostsCountQuery = groq`count(
  *[_type == "communityPost"
&& defined(slug.current)])`

export const getCommunityPostsCount = (
  client: SanityClient,
  tags: string[],
): Promise<number> => {
  return client.fetch(communityPostsCountQuery)
}

export const tagNamesQuery = groq`*[_type == "tag"]{name}`

export async function getTagNames(
  client: SanityClient,
): Promise<{ name: string }[]> {
  const tags = await client.fetch(tagNamesQuery)
  return tags
}

export const postBySlugQuery = groq`*[_type == "post" && slug.current == $slug][0]
{
  title,
  slug,
  date,
  description,
  mainImage,
  "tagNames": tags[]->name,
  body,
  author,
  siteName,
  link
}`

export async function getPost(
  client: SanityClient,
  slug: string,
): Promise<Post> {
  return await client.fetch(postBySlugQuery, {
    slug,
  })
}

export const postSlugsQuery = groq`
*[_type == "post" && defined(slug.current)][].slug.current
`

export const communityPostBySlugQuery = groq`*[_type == "communityPost" && slug.current == $slug][0]
{
  title,
  slug,
  mainImage,
  body
}`

export async function getCommunityPost(
  client: SanityClient,
  slug: string,
): Promise<CommunityPost> {
  return await client.fetch(communityPostBySlugQuery, {
    slug,
  })
}

export type Post = NewPost & {
  _id: string
  _createdAt: string
}

export interface NewPost {
  _type: 'post'
  title?: string
  slug: Slug
  date: string
  time: string
  description?: string
  mainImage?: ImageAsset
  tagNames: string[]
  body: PortableTextBlock[]
  siteName?: string
  link?: string
  author?: string
}

export interface CommunityPost {
  title: string
  slug: Slug
  body: string
  mainImage?: ImageAsset
  tagNames: string[]
}

const resourcesQuery = groq`*[_type == "sanity.fileAsset"] | order(title)`

export async function getResources(client: SanityClient): Promise<any> {
  return await client.fetch(resourcesQuery)
}

const resourceTagsQuery = groq`*[_type == "media.tag"]`

export async function getResourceTags(client: SanityClient): Promise<any> {
  return await client.fetch(resourceTagsQuery)
}

const resourceSourcesQuery = groq`array::unique(*[_type == "sanity.fileAsset"].source)`

export async function getResourceSources(client: SanityClient): Promise<any> {
  return await client.fetch(resourceSourcesQuery)
}

export const resourcesByTagsQuery = (tags: string[]) => {
  const tagsList = '[' + tags.map((tag) => `"${tag}"`).join(',') + ']'
  return groq`
*
[
  _type == "sanity.fileAsset"
  && 
  count((opt.media.tags[]->name.current)[@ in ${tagsList}]) > 0
] | order(title)`
}

export const resourcesBySourcesQuery = (sources: string[]) => {
  const sourcesList =
    '[' + sources.map((source) => `"${source}"`).join(',') + ']'
  return groq`
*
[
  _type == "sanity.fileAsset"
  && 
  source in ${sourcesList}
] | order(title)`
}

export const resourcesByTagsAndSourcesQuery = (
  tags: string[],
  sources: string[],
) => {
  const tagsList = '[' + tags.map((tag) => `"${tag}"`).join(',') + ']'
  const sourcesList =
    '[' + sources.map((source) => `"${source}"`).join(',') + ']'
  return groq`
*
[
  _type == "sanity.fileAsset"
  && 
  count((opt.media.tags[]->name.current)[@ in ${tagsList}]) > 0
  && source in ${sourcesList}
] | order(title)`
}

export async function getResourcesByTagsAndSources(
  client: SanityClient,
  tags: string[],
  sources: string[],
): Promise<Post[]> {
  if (tags.length === 0 && sources.length === 0) {
    return await client.fetch(resourcesQuery)
  } else if (sources.length === 0) {
    const preparedQuery = resourcesByTagsQuery(tags)
    return await client.fetch(preparedQuery)
  } else if (tags.length === 0) {
    const preparedQuery = resourcesBySourcesQuery(sources)
    return await client.fetch(preparedQuery)
  } else {
    const preparedQuery = resourcesByTagsAndSourcesQuery(tags, sources)
    return await client.fetch(preparedQuery)
  }
}
