import React, { useState, useEffect } from 'react'
import Link from 'next/link'
import {
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  CircularProgress,
  CardHeader,
} from '@mui/material'
import { getPostsByTags, getPostsCount, Post } from '~/lib/sanity.queries'
import { getClient } from '~/lib/sanity.client'
import NewsModal from './news-modal'
import { formatDate, formatDateForNews } from '~/utils'

const PAGE_SIZE = 5
const client = getClient(undefined)

const NewsFeed = () => {
  const [articles, setArticles] = useState<Post[]>([])
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const [maxPage, setMaxPage] = useState<number | null>(null)
  const [openModal, setOpenModal] = useState(false)
  const [selectedArticle, setSelectedArticle] = useState<Post | null>(null)

  const handleOpenModal = (article: Post) => {
    setSelectedArticle(article)
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  useEffect(() => {
    ;(async () => {
      setMaxPage(
        Math.ceil((await getPostsCount(client, ['Press'])) / PAGE_SIZE),
      )
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      const startIndex = PAGE_SIZE * (page - 1)
      const stopIndex = PAGE_SIZE * page
      const fetchedPosts = await getPostsByTags(
        client,
        ['Press'],
        startIndex,
        stopIndex,
      )

      setArticles((prev) => {
        if (
          prev.length === 0 ||
          prev[prev.length - 1].slug.current !==
            fetchedPosts[fetchedPosts.length - 1].slug.current
        ) {
          return [...prev, ...fetchedPosts]
        }
        return prev
      })

      setLoading(false)
    })()
  }, [page])

  const handleShowMore = () => {
    setPage((prev) => (prev >= maxPage ? maxPage : prev + 1))
  }

  return (
    <Box
      sx={{
        marginTop: 2,
        padding: 2,
        bgcolor: 'background.paper',
        borderRadius: 1,
      }}
    >
      <Typography variant="h5" sx={{ mb: 3 }}>
        News
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {articles.map((article) => (
          <Card
            key={article.slug.current}
            sx={{
              p: 2,
              backgroundColor:
                article.siteName === 'Sylv AI' ? '#15394d' : 'transparent',
              padding: article.siteName === 'Sylv AI' ? 1 : 0,
              borderRadius: 1,
              cursor: 'pointer',
              '&:hover': {
                backgroundColor:
                  article.siteName === 'Sylv AI' ? '#1f5573' : 'action.hover',
                borderColor: 'primary.main',
              },
              transition: 'background-color 0.2s, border-color 0.2s',
            }}
            onClick={() => handleOpenModal(article)}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingBottom: 0,
                marginBottom: 0,
              }}
            >
              <Box>
                <Typography
                  sx={{ display: 'inline-block', marginLeft: 1 }}
                  variant="h6"
                  component="h2"
                  gutterBottom
                >
                  <Typography
                    variant="h6"
                    component="span"
                    color="text.primary"
                  >
                    {article.title}
                  </Typography>
                </Typography>
              </Box>
              {article.siteName && (
                <Typography variant="body2" color="text.primary">
                  {article.siteName}
                </Typography>
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'left',
                textWrap: 'nowrap',
                marginLeft: 1,
              }}
            >
              <Typography variant="caption" color="text.primary">
                {formatDateForNews(article.time)}
              </Typography>
              <Typography
                variant="body1"
                sx={{ marginLeft: 0.5, marginRight: 0.5 }}
                color="text.secondary"
              >
                ⏺
              </Typography>
              <Typography
                variant="body1"
                noWrap
                sx={{
                  display: 'inline-block',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
                color="text.secondary"
              >
                {article.description.replaceAll('\n', ' ')}
              </Typography>
            </Box>
          </Card>
        ))}
        {loading && <CircularProgress />}
        {!loading && page < maxPage && (
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
            <Button variant="contained" onClick={handleShowMore}>
              Show More
            </Button>
          </Box>
        )}
      </Box>
      <NewsModal
        open={openModal}
        onClose={handleCloseModal}
        post={selectedArticle}
      />
    </Box>
  )
}

export default NewsFeed
